import React, { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react'
import { SectionCard, Button, ButtonTapHighlight } from '../../components'
import { RedDotLog, useOpenService, useOpenServiceUrl } from '../../hooks'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { CARPROFILE_SCHEME } from '../../utils'
import { UserMessageResType } from '@tmap-web-lib/remote-api-client/frontman'
import { useUpdateUserMessageExpire } from '../../react-query'

interface Props {
  userMessageData: UserMessageResType | undefined
  isRefetching: boolean
  redDotLog: RedDotLog
  eventLog: () => TmapLogBuilder
}

const MainPersonalizedMessage = forwardRef((props: Props, ref:ForwardedRef<HTMLDivElement>) => {
  const { userMessageData, isRefetching, redDotLog, eventLog } = props

  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()

  const { mutate } = useUpdateUserMessageExpire({ useErrorBoundary: false })

  const [isActive, setActive] = useState(false)
  const [isClosed, setClosed] = useState(false)
  const [userMessage, setUserMessage] = useState<UserMessageResType | null>(null)

  const handleClick = useCallback((closed?: boolean) => {
    if (!userMessage || (redDotLog === null)) return

    setActive(false)
    setClosed(true)
    setUserMessage(null)

    if (closed) {
      eventLog()
        .set('action_id', 'tap.cancel_customMessage')
        .add('customField', ['typeCode', userMessage.typeCode], ['detailCode', userMessage.detail.detailCode])
        .send()
    } else {
      eventLog()
        .set('action_id', 'tap.customMessage')
        .add('customField', ['typeCode', userMessage.typeCode], ['detailCode', userMessage.detail.detailCode], ['reddot', redDotLog])
        .send()

      // 개인화 메시지 클릭 시 해당 소재 만료 처리
      mutate({ typeCode: userMessage.typeCode, detailCode: userMessage.detail.detailCode })

      const landingUrl = userMessage.detail?.linkUrl
      const isNotLifeScheme = !landingUrl?.startsWith('tmap://life')

      if (isNotLifeScheme) {
        if (userMessage.typeCode === 'VEHICLE_REGISTRATION_GUIDE') {
          const extra = {
            serviceName: 'life',
            logbox: {
              type: 'tamp',
              origin: 'life'
            },
            action: {
              actionType: 'close'
            },
            pageType: [
              'manual-input-off'
            ]
          }
          const url = `${CARPROFILE_SCHEME}?pageid=register_car_profile&extra=${encodeURIComponent(JSON.stringify(extra))}`
          openService(landingUrl || url)
        } else {
          openService(landingUrl || '')
        }
      } else {
        if (landingUrl) {
          openServiceUrl(landingUrl)
        }
      }
    }
  }, [userMessage, redDotLog, openService, openServiceUrl, eventLog, mutate])

  useEffect(() => {
    if (!isRefetching) {
      setUserMessage(userMessageData || null)
    }
  },[userMessageData, isRefetching])

  useEffect(() => {
    if (!userMessage) return
    setTimeout(() => {
      setActive(true)
    },0)
  }, [userMessage])

  useEffect(() => {
    // 개인화 메세지 노출시 eventLog
    if (isClosed || (redDotLog === null)) return
    if (userMessage && isActive) {
      eventLog()
        .set('action_id', 'view.customMessage')
        .add('customField', ['typeCode', userMessage.typeCode], ['detailCode', userMessage.detail.detailCode], ['reddot', redDotLog])
        .send()
    }
  }, [userMessage, isActive, eventLog, isClosed, redDotLog])

  if (!userMessage) return null

  return (
    <div
      className={isActive ? 'opacity-slide-down-enter-active' : 'opacity-slide-down-exit-active'}
      style={{
        overflow: 'hidden',
        height: isActive ? '166px' : 0, // marginTop 12px 값 포함
      }}
      ref={ref}
    >
      <SectionCard>
        <div className="main_personalized">
          <h2 className="main_personalized_tit">{userMessage.detail.message}</h2>
          {
            userMessage.detail.linkText &&
            <Button
              variant={'primary'}
              styleWidth={'auto'}
              styleHeight={40}
              borderRadius={'6px'}
              onClick={() => handleClick()}
            >
              {userMessage.detail.linkText}
            </Button>
          }

          <ButtonTapHighlight
            isFullWidth={false}
            className="main_personalized_close"
            onClick={() => handleClick(true)}
          >
            <span className="blind">개인화 메세지 닫기</span>
          </ButtonTapHighlight>
          {
            userMessage.detail.iconUrl &&
            <img
              src={userMessage.detail.iconUrl}
              className="main_personalized_img"
              width="68"
              height="68"
              alt=""
            />
          }
        </div>
      </SectionCard>
    </div>
  )
})



export { MainPersonalizedMessage }
